import React from "react";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import Tariff from "../components/others/LoginScreen";
import Footer from "../components/layout/footer/Footer";

export default function Login() {
  return (
    <Layout>
      <Navbar darkBg />
      <div className="login-bottom-spacing">
        <Tariff />
        <Footer/>
      </div>
    </Layout>
  );
}
