import React from "react";

export default function CarTariff() {
  return (
    <div className="car-tariff-section">
      <h2 className="car-tariff-title text-center mb-4">Car Rental Tariff</h2>
      <div className="car-tariff-table-container">
        <table className="table car-tariff-table table-bordered table-hover">
          <thead className="car-tariff-header bg-primary text-white">
            <tr>
              <th>Car Type</th>
              <th>Seaters</th>
              <th>4 Hrs/ 40 Kms</th>
              <th>8 Hrs/ 80 Kms</th>
              <th>Additional Kms</th>
              <th>Additional Hrs</th>
              <th>Outstation Min. 250KM</th>
              <th>Outstation Add Per KM</th>
              <th>Driver Batta/Day</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Swift Dzire/ Toyota Etios</td>
              <td>4+1</td>
              <td>₹1,200/-</td>
              <td>₹2,400/-</td>
              <td>₹13/-</td>
              <td>₹220/-</td>
              <td>₹3,250/-</td>
              <td>₹13/-</td>
              <td>₹600/-</td>
            </tr>
            <tr>
              <td>Toyota Innova</td>
              <td>6+1</td>
              <td>₹1,800/-</td>
              <td>₹3,600/-</td>
              <td>₹18/-</td>
              <td>₹450/-</td>
              <td>₹4,500/-</td>
              <td>₹18/-</td>
              <td>₹600/-</td>
            </tr>
            <tr>
              <td>Innova Crysta</td>
              <td>7+1</td>
              <td>₹2,200/-</td>
              <td>₹4,400/-</td>
              <td>₹20/-</td>
              <td>₹500/-</td>
              <td>₹5,000/-</td>
              <td>₹20/-</td>
              <td>₹600/-</td>
            </tr>
            <tr>
              <td>Toyota Fortuner</td>
              <td>7+1</td>
              <td>₹3,800/-</td>
              <td>₹5,600/-</td>
              <td>₹55/-</td>
              <td>₹500/-</td>
              <td>₹13,750/-</td>
              <td>₹55/-</td>
              <td>₹600/-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
