import React, { useState } from "react";
import { Collapse, Card, Button } from "react-bootstrap";
import carimage from "../../img/imgs/policy-slider.png";
import Navbar from "../layout/header/Navbar";
import Footer from "../layout/footer/Footer";

const TermsAndConditions = () => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  return (
    <>
      <Navbar />
      <div className="terms-container mt-5">
        <h1>Terms and Conditions</h1>
        <section id="body-content">
          <div>
            <img src={carimage} alt="Policy" width="100%" loading="lazy" />
          </div>

          <div className="terms-section">
            <div className="container">
              <h2 class="section-title-lg">
                Terms <b>and</b> Conditions
              </h2>
              <p>
                Please Carefully Read These Terms And Conditions Before Using
                This sstranslinks Travels (P) Ltd. Web Site (The "Site"). By Using
                The Site You Indicate Your Agreement To These Terms And
                Conditions This Site Is The Property Of sstranslinks Travels (P) Ltd.
                ("sstranslinks") and Its Licensors And Is Protected By Copyright Laws
                And Other Intellectual Property Laws. sstranslinks expressly
                disclaims any warranties of merchantability or fitness.
              </p>

              <h5>For a particular purpose,</h5>
              <p>
                Interested Individuals May Download And Make Copies Of Portions
                Of This Site For Non-Commercial Use, Provided That You Retain
                Any Copyright Or Other Legal Notices That Appear On Such Copies.
                You May Not Modify The Site And You May Not Use The Site In Any
                Manner That Infringes The Rights Of Another Party Professional
                Tourism And Travel Organizations, Teachers, Schools,
                Universities And Educators May Establish A Link Or Links To
                sstranslinkstravels.Com Website Homepage. All Other Parties Desiring
                To Link To Harcourt Must Contact{" "}
                <a href="mailto:customercare@sstranslinkstravels.com">
                  customercare@sstranslinkstravels.com{" "}
                </a>
              </p>

              <p>
                To Request Permission, Subject To Compliance With The Following
                RequirementsYou May Not Frame Or Alter The Appearance Of
                sstranslinkstravels.Com. You May Neither State Nor Imply That sstranslinks
                Endorses, Sponsors Or Otherwise Approves Your Site Or Any Other
                Sites. You Must Abide By All Of The Other Terms And Conditions
                Of Use And Policies Set Forth Herein. For Example, You May Not
                Reproduce Or Host Harcourt Content On Your Website.You Agree Not
                To Portray sstranslinks In Any Negative Or Derogatory Manner. Your
                Site May Not Contain Any{" "}
              </p>

              <ul>
                <li>
                  {" "}
                  Vulgar, Obscene, Pornographic Or Indecent Language Or Images.{" "}
                </li>
                <li>
                  Material That Is Untrue, Defames, Abuses, Harasses Or
                  Threatens Others.
                </li>
                <li>
                  {" "}
                  Statements That Are Bigoted, Hateful, Or Racially Demeaning.
                </li>
                <li> Material Violating Any Person's Right Of Privacy. </li>
                <li>
                  {" "}
                  Material Involving Or Encouraging Gambling Or Other Illegal
                  Activities Or Unauthorized Use Of The Copyrighted Material Of
                  Others.
                </li>
              </ul>

              <p>
                sstranslinks Disclaims Any Responsibility For The Content Of Other
                Sites On The Internet, Which May Be Linked To The Site, Or Any
                Material Of Third Parties That May Be Accessed Through The Site.
                With Respect To Any Bulletin Boards Or Forums That Become
                Available On The Site, sstranslinks Disclaims Responsibility Or
                Liability For Any Statements Opinions Of Third Parties Expressed
                In Such Communications, And It Reserves The Right To Remove
                Material That Is Illegal, Offensive Or Otherwise Inappropriate.
              </p>

              <div className="accordion" id="accordionExample">
                <Card>
                  <Card.Header className="card-header">
                    <Button
                      variant="link"
                      onClick={() => setOpen1(!open1)}
                      aria-expanded={open1}
                      aria-controls="termsOne"
                      className="btn-block text-left"
                      aria-label="Toggle safety guidelines"
                    >
                      1. Safety Guidelines For Customers{" "}
                      <i
                        className={`fas fa-angle-${open1 ? "up" : "down"}`}
                      ></i>
                    </Button>
                  </Card.Header>
                  <Collapse in={open1}>
                    <Card.Body>
                      <ul>
                        <li>
                          Temperature check will be done before boarding the
                          bus. Passengers with symptoms will not be allowed to
                          board the bus.
                        </li>
                        <li>
                          The boot of the bus will be opened by the attender,
                          and customers are requested to handle luggage on their
                          own.
                        </li>
                        <li>
                          A free safety kit will be provided to each customer
                          during the boarding procedure.
                        </li>
                        <li>
                          Masks should be compulsorily worn throughout the
                          journey.
                        </li>
                        <li>
                          All passengers are advised to bring their own
                          bedspreads/blankets.
                        </li>
                        <li>
                          Avoid unnecessary movement/touching of common areas in
                          the bus.
                        </li>
                      </ul>
                    </Card.Body>
                  </Collapse>
                </Card>

                <Card>
                  <Card.Header>
                    <Button
                      variant="link"
                      onClick={() => setOpen2(!open2)}
                      aria-expanded={open2}
                      aria-controls="termsTwo"
                      className="btn-block text-left"
                    >
                      2. Payment{" "}
                      <i
                        className={`fas fa-angle-${open2 ? "up" : "down"}`}
                      ></i>
                    </Button>
                  </Card.Header>
                  <Collapse in={open2}>
                    <Card.Body>
                      <p>
                        On your credit card is processed by ICICI Payment
                        Gateway. Booking of tickets is subject to realization of
                        fare and the service charges (including Service Tax)
                        from ICICI Bank through the Payment Gateway Cancellation
                        Charges.You are required to pay the cost of the ticket,
                        Admin Charges and the service charge (including Service
                        Tax) through ICICI Payment gateway. Payment by credit
                        cards for Internet Booking Operated by ICICI Bank.
                        Payments through any Master / Visa credit cards issued
                        by any bank When you press pay button in ICICI payment
                        gateway page, the server will process your credit-card
                        in about 5 seconds, but it may be longer at certain
                        times. So wait for some more time. To avoid double
                        charge,Do not press the Submit buttonmore than once, and
                        do not press the back or Refresh buttons. For
                        submissionmore than Non-authorization of payment more
                        than once by payment gateway for tickets booked by you
                        is liable to result in deregistration of your account
                        with this site, without any Cancellation Procedure.
                      </p>
                    </Card.Body>
                  </Collapse>
                </Card>

                <Card>
                  <Card.Header>
                    <Button
                      variant="link"
                      onClick={() => setOpen3(!open3)}
                      aria-expanded={open3}
                      aria-controls="termsThree"
                      className="btn-block text-left"
                    >
                      3. E-Tickets Cancellation{" "}
                      <i
                        className={`fas fa-angle-${open3 ? "up" : "down"}`}
                      ></i>
                    </Button>
                  </Card.Header>
                  <Collapse in={open3}>
                    <Card.Body>
                      <ul>
                        <li>
                          {" "}
                          12 hours before Departure 15% cancellation Charges{" "}
                        </li>
                        <li>
                          Between 12 hours & 4hours before Departure 30%
                          cancellation charges
                        </li>
                        <li>
                          Between 4Hours & 3Hours Before Departure 60%
                          cancellation Charges.
                        </li>
                        <li>
                          Last 3 Hours Before Departure - 90% cancellation
                          charges.
                        </li>
                      </ul>
                      <p>
                        You cannot cancel tickets after the above mentioned
                        timelines and it will be considered as not traveled,/No
                        Non-Refundable Due to last minute breakdowns under
                        unavoidable conditions, the Company may use an alternate
                        vehicle or club it with other transporter. We request
                        the to co-operate with the Transporter under such
                        circumstances. The Company has the right to shift the
                        passenger's seat if the situation requires and customers
                        are requested to oblige such requests under genuine
                        circumstances. Bus may be delayed due to some
                        unavoidable reasons like traffic jams etc. Passengers
                        are requested to arrive at the boarding point at the
                        reporting time mentioned in the ticket. Passengers are
                        requested to show the E-Ticket before boarding the bus.
                        In case of any controversy, this may happen unlikely,
                        please show photo identity card. The company shall not
                        be responsible for any delay or inconvenience during the
                        journey due to breakdown of the vehicle or other reasons
                        beyond the control of the company.
                        <p>
                          Passengers are requested to carry a photo identity
                          card when boarding the bus with e-ticket,
                        </p>
                        <p>
                          If you have any suggestions or complaints, please call
                          us at{" "}
                          <b>
                            <a href="tel:+919840962134">+91-98409-62134</a>
                          </b>{" "}
                          or mail us to{" "}
                          <b>
                            <a href="mailto:customercare@sstranslinkstravels.com">
                              customercare@sstranslinkstravels.com{" "}
                            </a>
                          </b>
                        </p>
                      </p>
                    </Card.Body>
                  </Collapse>
                </Card>

                <Card>
                  <Card.Header>
                    <Button
                      variant="link"
                      onClick={() => setOpen4(!open4)}
                      aria-expanded={open4}
                      aria-controls="termsFour"
                      className="btn-block text-left"
                    >
                      4. Permitted Luggage{" "}
                      <i
                        className={`fas fa-angle-${open4 ? "up" : "down"}`}
                      ></i>
                    </Button>
                  </Card.Header>
                  <Collapse in={open4}>
                    <Card.Body>
                      <p>
                        We will carry your luggage on and subject to these
                        General Conditions of sstranslinks Travels (P) Ltd., And on
                        any applicable special conditions 20 kg per Ticket and
                        one small piece of hand luggage will be allowed. In this
                        context, hand means something that is capable of fitting
                        on an overhead luggage rack or under seats. If we agree
                        to carry any particular luggage on any journey this does
                        not mean that we have agreed to carry that luggage or
                        luggage exceeding the permitted quantity on any
                        subsequent journey you make.
                      </p>

                      <p>
                        Fragile items such as electrical goods portable
                        televisions and radio will only be carried if they are
                        reasonable size and securely fastened.
                      </p>

                      <p>
                        Such luggage shall not contain gold ornaments are
                        currency notes or any contraband materials Drivers will
                        load your luggage on or off coaches except where in the
                        reasonable opinion of the driver, your luggage exceeds
                        the recommended weight, whereby you will be responsible
                        for making arrangements for the lifting of your luggage,
                        on or off the coach. However the company is not at all
                        responsible for the missing,Damage &amp; lost luggage.
                      </p>

                      <p>
                        The passenger has to carry the baggages at their own
                        risk &amp; responsibility.
                      </p>
                    </Card.Body>
                  </Collapse>
                </Card>

                <Card>
                  <Card.Header>
                    <Button
                      variant="link"
                      onClick={() => setOpen5(!open5)}
                      aria-expanded={open5}
                      aria-controls="termsFive"
                      className="btn-block text-left"
                    >
                      5. Inspection of Luggage{" "}
                      <i
                        className={`fas fa-angle-${open5 ? "up" : "down"}`}
                      ></i>
                    </Button>
                  </Card.Header>
                  <Collapse in={open5}>
                    <Card.Body>
                      <p>
                        We shall be entitled to inspect all of your luggage, for
                        the purpose of ensuring compliance with the above
                        requirements. We shall not be obliged to carry you or
                        your luggage and shall be entitled to remove you from
                        the vehicle if you refuse to submit for a search.
                      </p>

                      <p>
                        Our liability for lost luggage: If you leave behind or
                        lose your luggage on a coach or at a station we shall
                        not have any responsibility for the luggage of the
                        passengers / loser. Our liability for loss or damage to
                        luggage.
                      </p>

                      <p>
                        Your luggage shall be at your risk at all times, and we
                        will not be liable for any loss of or damage to your
                        luggage caused by your negligence.
                      </p>
                    </Card.Body>
                  </Collapse>
                </Card>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
