import React, { useState } from 'react';
import Navbar from '../../layout/header/Navbar';
import Footer from '../../layout/footer/Footer';

export default function Tirupathi() {
  const [activeTab, setActiveTab] = useState('All Package');
  const tabs = [
    'All Package',
    '2 Days Package',
    '3 Days Package',
    '4 Days Package',
    '5 Days Package',
    '6 Days Package',
    '7 Days Package',
    '8 Days Package',
    '9 Days Package'
  ]; return (

    <>
      <Navbar></Navbar>
      <div className="tirupathi-wrapper">
        <div className="tirupathi-content">
          <div className="tirupathi-image-container">
            <img
              src="assets/img/tirupathihd.png"
              alt="Tirupati Temple"
              className="full-width-image"

            />
          </div>
        </div>

        <div className="tirupathi-info">
          <div className="tirupathi-image-text">
            <div className="circular-image-wrapper">
              <img
                src="assets/img/tirupathi1.jpg"
                alt="Devotional Image"
                className="circular-image"
              />
            </div>
            <div className="text-section">
              <h2>Decided to go on a <strong>DEVOTIONAL FAMILY TRIP</strong> this vacation?</h2>
              <p>
                Whatever may be the date & number of persons, book a luxurious
                journey with our exclusive Tirupathi tour packages from Pondicherry.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="tabs-container">
          {tabs.map(tab => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div> */}

        <div className="packages-container">
          <h1 className="packages-title">Our Packages from Pondicherry</h1>
        </div>

        {/* New Major Attractions Section */}
        <div className="attractions-section">
          <h2>MAJOR ATTRACTIONS IN TIRUPATI</h2>
          <p>
            Tirupati, an ancient city in Andhra Pradesh, is famous for its temples, which are frequented by people from all parts
            of India. Its century-old temples are famous among pilgrims. The Tirumala Hills in Tirupati are the second oldest
            rock mountains in the world. Though you have many places to visit in Tirupati, here are some important places to
            visit that would make you feel you have indeed toured the famous pilgrim center.
          </p>
          <div className="attractions-grid">
            <div className="attraction-item">
              <img
                src="assets/img/kanipakam.jpg" alt="Kanipakam Temple" />
              <p className="attraction-title">KANIPAKAM TEMPLE</p>
            </div>
            <div className="attraction-item">
              <img src="assets/img/Kalahasti.jpg" alt="Sri Kalahasti Temple" />
              <p className="attraction-title">SRI KALAHASTI TEMPLE</p>
            </div>
            <div className="attraction-item">
              <img src="assets/img/sri.jpg" alt="Srivari Museum" />
              <p className="attraction-title">Srivari Museum</p>
            </div>
            <div className="attraction-item">
              <img src="assets/img/talakona.jpg" alt="Deer Park" />
              <p className="attraction-title">Talakona WaterFalls</p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
