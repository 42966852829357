import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom';

export default function Screenshot({ hasBg }) {
  const options = {
    loop: true,
    margin: 0,
    center: true,
    dots: true,
    nav: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      991: {
        items: 4,
      },
      1200: {
        items: 4,
      },
      1920: {
        items: 4,
      },
    },
  };
  return (
    <>
      <section
        id="screenshots"
        className={`screenshots-section ptb-100 ${
          hasBg ? "gray-light-bg" : ""
        }`}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center">
                {hasBg ? (
                  <span className="text-uppercase color-secondary sub-title">
                    Gallery Showcase
                  </span>
                ) : (
                  ""
                )}
                <h2>Explore Our Stunning Gallery</h2>
                <p className="lead">
                Discover captivating visuals that showcase the essence of our experiences.
                Dive into our gallery and get a glimpse of the breathtaking moments we capture.
                </p>
              </div>
            </div>
          </div>
            <div class="col-sm-12 mt-3 see-more">
              <Link to="/gallery">
                 <button type="submit" class="btn solid-btn" id="btnContactUs">
                    See More &gt;&gt;
                 </button>
              </Link>
            </div>
          <div className="screen-slider-content mt-5">
            <div className="screenshot-frame"></div>
            <OwlCarousel
              className="screen-carousel owl-carousel owl-theme dot-indicator"
              {...options}
            >
              <img
                src="assets/gallery/sml-bus1.png"
                className="img-fluid"
                alt="screenshots"
              />
              <img
                src="assets/gallery/sml-bus2.png"
                className="img-fluid"
                alt="screenshots"
              />
              <img
                src="assets/gallery/sml-bus3.png"
                className="img-fluid"
                alt="screenshots"
              />
                <img
                src="assets/gallery/sml-bus1.png"
                className="img-fluid"
                alt="screenshots"
              />
              <img
                src="assets/gallery/sml-bus2.png"
                className="img-fluid"
                alt="screenshots"
              />
              <img
                src="assets/gallery/sml-bus3.png"
                className="img-fluid"
                alt="screenshots"
              />
            </OwlCarousel>
          
          </div>
        </div>
       
      </section>

    </>
  );
}
