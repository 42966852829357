import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${
            darkBg ? "bg-transparent" : "custom-nav white-bg"
          } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img
                src={
                  scroll > headerTop
                    ? "assets/img/ssTL.png"
                    : "assets/img/ssTL.png"
                }
                width="220"
                alt="logo"
                className="img-fluid main-logo"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse main-menu text-center"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" smooth to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#about">
                    About
                  </HashLink>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link  dropdown-toggle"
                    to="/"
                    id="navbarBlogPage"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Rental
                  </Link>

                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarBlogPage"
                  >
                    <Link className="dropdown-item" to="/car-rental">
                      Car Rental
                    </Link>
                    <Link className="dropdown-item" to="/van-rental">
                      Van Rental
                    </Link>
                    <Link className="dropdown-item" to="/bus-rental">
                      Bus Rental
                    </Link>
                  </div>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#screenshots">
                    Gallery
                  </HashLink>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/tariff">
                    Tariff
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link  dropdown-toggle"
                    to="/"
                    id="navbarBlogPage"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Package
                  </Link>

                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarBlogPage"
                  >
                    <Link className="dropdown-item" to="/tirupathi">
                      Thirupathi Package
                    </Link>
                    <Link className="dropdown-item" to="/aarupadai">
                      Aarupadai Veedu Package
                    </Link>
                  </div>
                </li>

                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#contact">
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>
            {/* <img
              src="assets/img/SANGEETHA_T.png"
              width="200"
              alt="logo"
              className="img-fluid"
            /> */}
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
