import React from "react";

export default function FeatureImg({ ImgSource }) {
  return (
    <>
      <div id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Best features
                </span>
                <h2>Easy Booking with Incredible Features</h2>
                <p>
                  Effortlessly plan your next trip with our intuitive booking system. 
                  Explore exciting destinations, customize your itinerary, 
                  and experience seamless travel arrangements at your fingertips.
                </p>
              </div>
            </div>
          </div>
          <div className="row row-grid align-items-center">
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-1 rounded-circle">
                    <span className="ti-face-smile"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Easy Booking Process</h5>
                  <p className="mb-0">
                    Book your dream trip in just a few clicks. 
                    Our platform makes travel planning seamless and hassle-free.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-2 rounded-circle">
                    <span className="ti-vector"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Personalized Travel Recommendations</h5>
                  <p className="mb-0">
                    Get tailored recommendations based on your interests. 
                    Discover destinations and experiences just for you.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-3 rounded-circle">
                    <span className="ti-headphone-alt"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>24/7 Customer Support</h5>
                  <p className="mb-0">
                    Our expert support team is available round-the-clock. 
                    We're always here to help with any travel inquiries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="position-relative" style={{ zindex: 10 }}>
                <img
                  alt="placeholder"
                  src={ImgSource}
                  className="img-center img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-media-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>New Destinations, New Adventures</h5>
                  <p className="mb-0">
                     Explore exciting new destinations regularly. 
                     Stay updated with fresh travel options and hidden gems..
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-5 rounded-circle">
                    <span className="ti-layout-cta-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Customizable Travel Plans</h5>
                  <p className="mb-0">
                     Create your perfect trip with fully customizable itineraries. 
                     Design your journey exactly how you want it.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-6 rounded-circle">
                    <span className="ti-palette"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Eco-Friendly Travel Options</h5>
                  <p className="mb-0">
                     Travel responsibly with sustainable options. 
                     Enjoy unforgettable experiences while caring for the planet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
