import React from "react";
import { Routes, Route } from "react-router-dom";
import ChangePassword from "../components/others/ChangePassword";
import ComingSoon from "../components/others/ComingSoon";
import LoginTwo from "../components/others/LoginTwo";
import NotFound from "../components/others/NotFound";
import PasswordReset from "../components/others/PasswordReset";
import ThankYou from "../components/others/ThankYou";
import Tariff from "../pages/Tariff";
import Rental from "../components/others/Rental/Rental";
import CarRental from "../components/others/Rental/CarRental";
import VanRental from "../components/others/Rental/VanRental";
import BusRental from "../components/others/Rental/BusRental";
import HomeEight from "../themes/index-8/HomeEight";
import OurTeam from "../pages/OurTeam";
import TeamDetails from "../pages/TeamDetails";
import Download from "../pages/Download";
import Review from "../pages/Review";
import Faq from "../pages/Faq";
import SignupTwo from "../components/others/SignupTwo";
import SignupPage from "../pages/SignupPage";
import BlogGrid from "../pages/BlogGrid";
import BlogWithSidebar from "../pages/BlogWithSidebar";
import BlogDetails from "../pages/BlogDetails";
import ScrollToTop from "./ScrollToTop";
import Gallery from "../pages/Gallery";
import Tirupathi from "../components/others/packages/Tirupathi";
import Aarupadai from "../components/others/packages/Aarupadai";
import TermsAndConditions from "../components/others/TermsandCondtion";
import PrivacyPolicy from "../components/others/PrivecyPolicy";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeEight />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/tariff" element={<Tariff />} />
        <Route path ="/rental" element={<Rental/>} />
        <Route path ="/car-rental" element={<CarRental/>} />
        <Route path ="/bus-rental" element={<BusRental/>} />
        <Route path ="/van-rental" element={<VanRental/>} />
        <Route path="/login-two" element={<LoginTwo />} />
        <Route path="/signup-two" element={<SignupTwo />} />
        <Route path="/basic-signup" element={<SignupPage />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/download" element={<Download />} />
        <Route path="/review" element={<Review />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/blog-grid" element={<BlogGrid />} />
        <Route path="/blog-sidebar" element={<BlogWithSidebar />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/tirupathi" element={<Tirupathi />} />
        <Route path="/aarupadai" element={<Aarupadai />} />
        <Route path="/privecy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<TermsAndConditions />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
