import React from "react";
import CarRental from "./CarRental";
import VanRental from "./VanRental";
import BusRental from "./BusRental";
import Footer from "../../layout/footer/Footer";

export default function Rental() {
  return (
    <section className="tariff-screen-section ptb-300 full-screen">
      <div className="rental">
            <CarRental />
            <VanRental />
            <BusRental />
          <Footer />
   
      </div>
    </section>
  );
}
