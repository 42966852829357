import React from 'react';
import Navbar from '../components/layout/header/Navbar';
import Layout from '../components/layout/Layout';


const images = [
  '/assets/gallery/sml-bus1.png',
  '/assets/gallery/sml-bus2.png',
  '/assets/gallery/sml-bus3.png',
  '/assets/gallery/innova.jpg',
  '/assets/gallery/innova2.jpg',
  '/assets/gallery/single-bus.jpg',
];

const Gallery = () => {
  return (
    <Layout>
      <Navbar classOption="custom-header" />
    <div className="gallery-container">
        <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center">
                  <span className="text-uppercase color-secondary sub-title">
                    Gallery Showcase
                  </span>
                <h2>Explore Our Stunning Gallery</h2>
                <p className="lead">
                Discover captivating visuals that showcase the essence of our experiences.
                Dive into our gallery and get a glimpse of the breathtaking moments we capture.
                </p>
              </div>
            </div>
          </div>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image} alt={`Gallery Image ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  </Layout>
  );
};

export default Gallery;
