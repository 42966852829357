import React from "react";

export default function VanTariff() {
  return (
    <div className="van-tariff-section">
      <h2 className="van-tariff-title text-center mb-4 mt-5">
        Van Rental Tariff
      </h2>
      <div className="van-tariff-table-container">
        <table className="table van-tariff-table table-bordered table-hover">
          <thead className="bg-primary text-white">
            <tr>
              <th>Van Type</th>
              <th>Seaters</th>
              <th>5 Hrs/ 50 Kms</th>
              <th>10 Hrs/ 100 Kms</th>
              <th>15 Hrs/ 150 Kms</th>
              <th>Additional Kms</th>
              <th>Additional Hrs</th>
              <th>Outstation Min. 250KM</th>
              <th>Outstation Add Per KM</th>
              <th>Driver Batta/Day</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Tempo Traveller</td>
              <td>12</td>
              <td>₹3,000/-</td>
              <td>₹6,000/-</td>
              <td>₹9,000/-</td>
              <td>₹22/-</td>
              <td>₹750/-</td>
              <td>₹5,500/-</td>
              <td>₹22/-</td>
              <td>₹700/-</td>
            </tr>
            <tr>
              <td>Luxury Tempo</td>
              <td>14</td>
              <td>₹3,000/-</td>
              <td>₹6,000/-</td>
              <td>₹9,000/-</td>
              <td>₹22/-</td>
              <td>₹750/-</td>
              <td>₹5,500/-</td>
              <td>₹22/-</td>
              <td>₹700/-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
