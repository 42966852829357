import React from "react";
import CarTariff from "../Tariff/CarTariff";
import kiwi from "../../../img/imgs/maruti-suzuki-swift--removebg-preview.png";
import maruti_swift1 from "../../../img/kiwi1.png";
import maruti_swift from "../../../img/imgs/renault-kwid--removebg-preview.png";
import Navbar from "../../layout/header/Navbar";
import Footer from "../../layout/footer/Footer";

const CarRental = () => {
  const images = [kiwi, maruti_swift1, maruti_swift];

  const getRandomImage = () =>
    images[Math.floor(Math.random() * images.length)];

  const sections = [
    {
      title: "Toyota Glanza Car Rental",
      content: (
        <>
          <p>
            Our car rental service in Chennai is successful. This is because of
            the outstanding and truthful service that we at Chennai Travels,
            since time never-ending. So, our customers can enjoy their travel to
            the maximum by getting a rental car in Chennai.
          </p>
          <p>
            We at Chennai Travels make sure that your trip a luxurious one. Thus
            you can travel around to the various parts of Chennai with an open
            occasion to view the exotic natural treasures of Chennai.
          </p>
          <p>
            We are offering car rental services with drivers for business trips
            and other vacation trips all over the places from Chennai. Car for
            Rental in Chennai is also available with proper placement of
            drivers. We are offering these services with properly maintained and
            conditioned vehicles with A/C provisions.
          </p>
        </>
      ),
    },
    {
      title: "Our Extensive Car Rental Service in Chennai",
      subtitle: "Luxury Cars For Rent",
      content: (
        <>
          <p>
            Chennai Travels makes corporate car rentals easy with a great
            selection of vehicles, perfect for any company need. Whether it’s
            for meetings or client visits, we’ve got you covered with flexible
            bookings and top-notch service!
          </p>
        </>
      ),
    },
    {
      title: "Wedding Cars for Rent",
      content: (
        <p>
          If you are dreaming of owning a grand wedding experience you can
          obviously prefer our wedding car rental service in Chennai. For
          wedding purposes, we are ready to serve you with flowery decorations
          in a personalized way meeting your budget and necessities.
        </p>
      ),
    },
    {
      title: "Monthly Car Rental",
      content: (
        <p>
          At Chennai Travels we provide cars for monthly rentals with drivers if
          you wish. At sometimes, cars have to be hired for occasional plans and
          regular corporate trips and events. You can save a certain amount of
          money if you tend to prefer monthly rental packages.
        </p>
      ),
    },
    {
      title: "Employee Car Rental Service",
      content: (
        <p>
          The cheapest Car Rentals in Chennai are accessible and available for
          employee transportations. Employees include both private and
          government sectors. Car rental in Chennai is made stress-free through
          us.
        </p>
      ),
    },
    {
      title: "Luxury Cars For Rent",
      content: (
        <p>
          If you are dreaming of owning a grand wedding experience you can
          obviously prefer our wedding car rental service in Chennai. For
          wedding purposes, we are ready to serve you with flowery decorations
          in a personalized way meeting your budget and necessities.
        </p>
      ),
    },
    {
      title: "Wedding Cars for Rent",
      content: (
        <p>
          If you are dreaming of owning a grand wedding experience you can
          obviously prefer our wedding car rental service in Chennai. For
          wedding purposes, we are ready to serve you with flowery decorations
          in a personalized way meeting your budget and necessities.
        </p>
      ),
    },
    {
      title: "Wedding Cars for Rent",
      content: (
        <p>
          If you are dreaming of owning a grand wedding experience you can
          obviously prefer our wedding car rental service in Chennai. For
          wedding purposes, we are ready to serve you with flowery decorations
          in a personalized way meeting your budget and necessities.
        </p>
      ),
    },
    {
      title: "Wedding Cars for Rent",
      content: (
        <p>
          If you are dreaming of owning a grand wedding experience you can
          obviously prefer our wedding car rental service in Chennai. For
          wedding purposes, we are ready to serve you with flowery decorations
          in a personalized way meeting your budget and necessities.
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="rental-package container py-5">
        <Navbar />
        <CarTariff className="car-tariff-scroll" />
        <div className="row car-rental">
          <h3 className="car-rental-sub">Car Rental</h3>

          {sections.map((section, index) => (
            <div className="row align-items-center mb-4" key={index}>
              {index % 2 === 0 ? (
                <>
                  <div className="col-lg-6 col-md-12 text-content">
                    <h2>{section.title}</h2>
                    {section.subtitle && <h4>{section.subtitle}</h4>}
                    {section.content}
                  </div>
                  <div className="col-lg-6 col-md-12 image-content mt-4 mt-md-0">
                    <img
                      src={getRandomImage()}
                      alt={section.title}
                      className="img-fluid"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 col-md-12 image-content mt-4 mt-md-0">
                    <img
                      src={getRandomImage()}
                      alt={section.title}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 text-content">
                    <h2>{section.title}</h2>
                    {section.subtitle && <h4>{section.subtitle}</h4>}
                    {section.content}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default CarRental;
