import React from 'react';
import { Card, Row, Col, ListGroup } from 'react-bootstrap';
import Navbar from '../../layout/header/Navbar';
import Footer from '../../layout/footer/Footer';
import Thiruparankundram from "../../../img/Thiruparankundram.jpg";
import Swamimalai from "../../../img/Swamimalai.jpg";
import Thiruchendur from "../../../img/thiruchendur.jpg";
import Tiruthani from "../../../img/tiruthani.jpg";
import Palani2 from "../../../img/palani2.jpg";
import Pazhamudircholai from "../../../img/PAZHAMUDIRCHOLAI.jpg";

const Aarupadai = () => {
  const itinerary = [
    {
      day: 'Pondicherry to Swamiamlai-Tiruchednur',
      image: Swamimalai,
      activities: [
        { activity: 'Today Morning 6.00 AM Pondicherry Pickup to Swamiamlai, take a travel 3 hours, reach at Swamiamlai darshan to Fourth Abode Among the Arupadai Veedu Of Lord Murugan temple after Darshan Proceed to Tiruchendur, reach at Tiruchendur Check in to hotel and Leisure.- Night Stay at Tiruchendur' },
      ],
    },
    {
      day: 'Tiruchendur-Thiruparankundram-Pazhamudirchoola-Palani',
      image: Thiruchendur,
      activities: [
        { activity: 'Today after Breakfast Darshan to Fifth Abode among the Arupadai Veedu of Tiruchendur Murugan temple after Darshan Checkout from the hotel Proceed to Thiruparankundram, reach at Thiruparankundram Darshan to First Abode Among the Arupadai Veedu Of God Murugan temple after Darshan Start Your Journey Pazhamudhircholai, One hour Drive reach at Pazhamudhircholai Darshan to Sixth Abode among the Arupadai Veedu Of Murugan temple after Darshan Proceed to Palani Overnight hotel transfer.- Night Stay at Palani' },
      ],
    },
    {
      day: 'Palani to Thiruthani',
      image: Palani2,
      activities: [
        { activity: 'Today Morning Breakfast at Hotel after Breakfast Check out from the hotel start to drive Third Abode among the Arupadai Veedu of Murugan temple after Darshan Proceed to Thiruthani, Reach at Thiruthani Check in to hotel and Leisure.- Night Stay at Thiruthani' },
      ],
    },

    {
      day: 'Thiruthani-Pondicherry Drop',
      image: Tiruthani,
      activities: [
        { activity: 'Today Morning after breakfast Check out from the hotel Start to drive Thiruthani Darshan to Fifth Abode Among the Arupadai Veedu Of Lord Murugan temple after Darshan Proceed to Pondicherry Drop' },
      ],
    },
  ];

  return (
    <>
      <Navbar />
      <div className="container my-5">
        <div className="murugan-content">
          <div className="murugan-image-container">
            <img
              src="assets/img/palani.jpg"
              alt="Murugan Temple"
              className="muruganfull-width-image"
            />
            <div className='murugan-image-content'>
              <h2>3 Nights 4 Days Arupadai Veedu Tour Package from Pondicherry</h2>
              <p>
                Welcome to Arupadai Veedu Tour Package from Pondicherry. We Offer Arupadai Veedu Tour Package Pondicherry Arranged by Road variety of Individual Vehicle with Accomodation at Reasonable Price. For Booking Arupadai Veedu Muurgan temple Tour Package from Pondicherry Please call us or Whatsapp char at +91 9865269153            </p>
            </div>
          </div>
          <div className="murugan-section">
            <h2>Arupadai Veedu Temple Destination:  <strong>Swamimalai-Tiruchednur-Thiruparankundram-Pazhamudirchoolai-Palani-Thiruthani</strong> </h2>
            <h2>Duration:  <strong>03 Nights  / 04 Days</strong> </h2>

            <p>
              Whatever may be the date & number of persons, book a luxurious
              journey with our exclusive Tirupathi tour packages from Pondicherry.
            </p>
          </div>
        </div>
        {itinerary.map((dayPlan, index) => (
          <Card key={index} className="mb-4 shadow-sm">
            <Card.Body>
              <Row>
                {/* Responsive Image Column */}
                <Col xs={12} md={4} className="d-flex justify-content-center align-items-center mb-3 mb-md-0">
                  <img
                    src={dayPlan.image}
                    alt="Temple"
                    className="img-fluid rounded"
                    style={{ maxHeight: '250px', objectFit: 'cover' }}
                  />
                </Col>

                {/* Content Column */}
                <Col xs={12} md={8}>
                  <h5 className="text-muted mb-3">{dayPlan.day}</h5>
                  <ListGroup variant="flush">
                    {dayPlan.activities.map((item, idx) => (
                      <ListGroup.Item
                        key={idx}
                        className="d-flex justify-content-between align-items-start"
                      >
                        <span>{item.activity}</span>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Aarupadai;
