import React from "react";

export default function BusTariff() {
  return (
    <div className="bus-tariff-section">
      <h2 className="bus-tariff-title text-center mb-4 mt-5">
        Bus Rental Tariff
      </h2>
      <div className="bus-tariff-table-container">
        <table className="table bus-tariff-table table-bordered table-hover">
          <thead className="bus-tariff-header bg-primary text-white">
            <tr>
              <th>Bus Type</th>
              <th>Seaters</th>
              <th>5 Hrs/ 50 Kms</th>
              <th>10 Hrs/ 100 Kms</th>
              <th>Additional Kms</th>
              <th>Additional Hrs</th>
              <th>Outstation Min. 250KM</th>
              <th>Outstation Add Per KM</th>
              <th>Driver Batta/Day</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SML Executive Seats</td>
              <td>22</td>
              <td>₹4,500/-</td>
              <td>₹9,000/-</td>
              <td>₹35/-</td>
              <td>₹800/-</td>
              <td>₹8,750/-</td>
              <td>₹35/-</td>
              <td>₹750/-</td>
            </tr>
            <tr>
              <td>Bharath Benz Executive Seats</td>
              <td>45</td>
              <td>-</td>
              <td>₹16,000/-</td>
              <td>₹80/-</td>
              <td>₹1,000/-</td>
              <td>₹17,000/-</td>
              <td>₹75/-</td>
              <td>₹1,000/-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
