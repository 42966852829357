import React from "react";
// import carImage from "../../../img/car.jpg";
// import carImage1 from "../../../img/car1.jpg";
import carImage2 from "../../../img/bus2.jpg";
import VanTariff from "../Tariff/VanTariff";
import Navbar from "../../layout/header/Navbar";
import Footer from "../../layout/footer/Footer";
import kiwi from "../../../img/imgs/van-removebg-preview.png";
import maruti_swift from "../../../img/imgs/bus-removebg-preview.png";
import maruti_swift1 from "../../../img/imgs/van-removebg-preview.png";

const VanRental = () => {
  const images = [kiwi, maruti_swift1, maruti_swift];
  const sections = [
    {
      title: "Tempo Traveller Rental Chennai",
      content: (
        <>
          <p>
            With our vast customer base developed over years of hard work and
            dedicated service, we offer the best prices for tempo traveller for
            rent in Chennai. It is not competitive as others cannot afford to
            offer our prices as we do a high volume of travel rental business to
            provide the most affordable costs for our customers. But it is never
            at the expense of the quality of service or any let out in the best
            conditions of the tempo travellers. We maintain the vehicles in the
            best of requirements at all times to not have any issue on trips.
            And our track record shows almost nil of such instances for the past
            many years. We have customers who need our tempo travellers for a
            pickup from, airport or railway station. Hence we offer them the
            most affordable tempo traveller for rent per km only. Not only for a
            pickup but also many other purposes, we also offer tempo traveller
            rent per day at the best prices. And in case of us sending
            experienced drivers, there is need to pay money for them or our
            customers can arrange for their drivers to use the tempo traveller
            for the day for the minimum costs among all the rental business in
            Chennai.
          </p>
        </>
      ),
    },
    {
      title: "Tempo Traveller Rental Chennai",
      content: (
        <>
          <p>
            Tempo Traveler is a standard reference for minibuses not only in
            Chennai but for all of Tamil Nadu. For any travel local or
            outstation, many ask for Tempo Travel rather than asking for
            minibuses. Such is the reach and popularity of Tempo Traveler for
            the Tamil Nadu people and in that case for most of South India. And
            the best place to book Tempo Travel rentals it is none other than
            Chennaitravels.in as we are the most trusted tempo traveller rental
            Chennai.
          </p>
          <p>
            Chennaitravels.in earned the trust of its customers who not only
            repeatedly avail our services but also recommend to their friends
            and relatives. And also it is not only for luxury tempo traveller in
            Chennai but to many outstations for both officials, spiritual and
            holiday joy trips. We offer luxury tempo travellers with as many
            seats needed for our customers to increase their comfortable
            journey. It may be 12,15 and 18, and with our many tempo travellers
            availabilities, it is easy for us to deliver them as per their
            requirement. And also we make sure that we provide only experienced
            drivers who know the way to make the trip in the luxury tempo
            traveller in Chennai the best experience not to forget down the
            memory lane.
          </p>
        </>
      ),
    },

    {
      title: "Our Extensive Car Rental Service in Chennai",
      content: (
        <>
          <p>
            Chennai Travels makes corporate car rentals easy with a great
            selection of vehicles, perfect for any company need. Whether it’s
            for meetings or client visits, we’ve got you covered with flexible
            bookings and top-notch service!
          </p>
        </>
      ),
    },
    {
      title: "Have a happy trip from Chennai to Yercaud tempo traveller trip",
      content: (
        <p>
          Want to take a weekend Chennai to Yercaud tempo traveller trip, then
          Chennaitravels.in is the best choice. We will make the trip to this
          Yelagiri hills on the eastern ghats near Salem a wonderful experience.
          Though known as the Poor man’s Ooty, we will make it a rich experience
          with all facilities in the Tempo Traveler from seating to luggage
          arrangements and others. We take only less than six hours to reach
          Yercaud via both the national highways of NH 32 and NH 179 as per the
          preference of our customers. Our experienced drivers know all the best
          tourist places in Yercaud to be the free guide to make the trip an
          enjoyable experience never to forget in life.
        </p>
      ),
    },
    {
      title: "Aarupadai Veedu",
      content: (
        <p>
          Tour Itinerary: 3 Night / 4 Days Destination: –Thiruthani – Swamimalai
          – Palani –Palamudirchoolai- Thiruparankundram – Thiruchendur. Day
          1:Today Morning 5.00 AM Chennai pickup to Tiruthani Murugan Temple
          Visit and after visit proceed to Swamimalai and Evening Visit to
          Swamimalai Murugan Temple visit and after visit drive to hotel – Night
          Stay at Kumbakonam. Day 2: Today Morning Start to Drive kumbakonam and
          on the way good hotel breakfast and after breakfast proceed to
          Palanimalai Murugan Temple Visit and after visit proceed to Madurai –
          Night Stay at Madurai.
        </p>
      ),
    },
    {
      title: "Aarupadai Veedu",
      content: (
        <p>
          Day 3: Today Morning after Breakfast drive toThiruparankundram Murugan
          temple visit & PalamudirChoolai Temple Visit and After Visit proceed
          to thiruchendur – Night Stay Tiruchendur . Day 4: Today Morning after
          Breakfast Dharsan to Tiruchendur Murugan Temple and after dharsan drop
          at Madurai Airport (or) 10.00 AM checkout from the hotel proceed to
          Chennai Over Night Drop.
        </p>
      ),
    },
    {
      title: "Tour Itinerary: 6 Night / 7 Days",
      content: (
        <p>
          Day 1 – Arrivel Chennai: Arrival at Chennai You Will be received and
          transferred to hotel . Overnight will be at hotel. Day 2 – Chennai –
          Tiruttani – Kancheepuram – Chennai: Drive in the morning to Tiruttani
          one of the six abodes of Lord Murugan ( Driving distance 90 kms,
          driving time 1 .5hrs). After temple visit drive to Kancheepuram. Visit
          to Varadaraja Perumal temple, Kailasanathar, Vaikuntanathar and
          Kamakshi Amman temples. Drive back to Chennai. – Overnight hotel in
          Chennai.
        </p>
      ),
    },
    {
      title: "Tour Itinerary: 6 Night / 7 Days",
      content: (
        <p>
          Day 3 – Chennai – Madurai: Our Chennai travels car rental service in
          Chennai is successful. This is because of the outstanding Stating at
          Chennai to Madhurai Breakfast and lunch on the way , Reaching Madhurai
          Pazhamuthircholia and Thiruparankundram which are among the six abodes
          of Lord Murugan. – Overnight hotel in Madurai.
        </p>
      ),
    },
    {
      title: "Tour Itinerary: 6 Night / 7 Days",
      content: (
        <p>
          Day 4 – Madurai – Palani – Madurai: Today Morning Visit the famous
          Meenakshi Sundareswarar temple, one of the holiest places of worship
          in South India. Later visit the Thirumalai Nayakan Palace. Afternoon
          Drive to Palani ( Driving distance 118 kms, driving time 2 hrs). Visit
          the temple – 4th in the series of Arupadai Vedu. Later drive back to
          Madurai. – Overnight hotel in Madurai. Day 5 – Madurai – Tiruchendur –
          Madurai: Drive to Tiruchendur (Driving distance 220 kms, driving time
          5 hrs). Visit the temple which is the 5th abode of Lord Murugan. Later
          drive back to Madurai. – Overnight hotel in Madurai.
        </p>
      ),
    },
    {
      title: "Tour Itinerary: 6 Night / 7 Days",
      content: (
        <p>
          Day 6 – Madurai – Thanjavur: Today Morning 5.00 AM Drive from Madurai
          to Thanjavur via Tiruchirapalli (driving distance 130 kms, driving
          time 2 hrs ). Upon arrival at Thanjavur check into the hotel.
          Afternoon Visit to Swami Malai The last in the series of the six
          abodes of Lord Murugan. Later visit the very famous Brihadeshwara
          temple also the known as the big temple. – Overnight hotel in
          Thanjavur. Day 7 – Thanjavur – Chennai: Today Morning Free for
          shopping or own activities or even take an optional tour to
          Gangaikonda Cholapuram / Darasuram / Kumbakonam. Return Comeback
          Overnight Chennai Airport or Central Railway station Dro
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="rental-package container py-5">
        <Navbar />
        <VanTariff></VanTariff>
        <div className="row car-rental">
          <h3 className="car-rental-sub">Van Rental</h3>

          {sections.map((section, index) => (
            <div className="row align-items-center mb-4" key={index}>
              {index % 2 === 0 ? (
                <>
                  <div className="col-lg-6 col-md-12 text-content">
                    <h2>{section.title}</h2>
                    {section.subtitle && <h4>{section.subtitle}</h4>}
                    {section.content}
                  </div>
                  <div className="col-lg-6 col-md-12 image-content mt-4 mt-md-0">
                    <img
                      src={images[index % images.length]}
                      alt={section.title}
                      className="img-fluid"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 col-md-12 image-content mt-4 mt-md-0">
                    <img
                      src={images[index % images.length]}
                      alt={section.title}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 text-content">
                    <h2>{section.title}</h2>
                    {section.subtitle && <h4>{section.subtitle}</h4>}
                    {section.content}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default VanRental;
