export default function Price({ hasBg }) {
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${
            hasBg ? "gray-light-bg" : "background-shape-right"
          } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                    Affordable Pricing and Packages <br />
                    Choose your Best One
                  </h2>
                  <p className="lead">
                    Choose from a variety of affordable travel packages 
                    tailored to your needs, ensuring you get the best 
                    value for your dream vacation.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Basic Travel Package</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/basic.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>5 Users access at the same time</li>
                      <li>Basic itinerary customization</li>
                      <li>Weekly travel updates</li>
                      <li>24/7 customer support</li>
                      <li>Event and activity recommendations</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">29</span>
                      </div>
                    </div>
                    <a href="#/" className="btn outline-btn" target="_blank">
                       Booking now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center popular-price single-pricing-pack p-5">
                  <h5 className="mb-2">Standard Travel Package</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/standard.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Access for 10 users</li>
                      {/* <li>Travel booking & eCommerce integration</li> */}
                      <li>Advanced itinerary customization</li>
                      <li>Daily updates on destinations</li>
                      <li>24/7 customer support</li>
                      <li>Event & destination analytics</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">145</span>
                      </div>
                    </div>
                    <a href="#/" className="btn solid-btn" target="_blank">
                      Booking now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Unlimited Travel Package</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/unlimited.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Access for 20 users</li>
                      <li>Fully customized itineraries</li>
                      <li>Unlimited updates on destinations</li>
                      <li>24/7 customer support</li>
                      <li>In-depth event analytics</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">249</span>
                      </div>
                    </div>
                    <a href="#/" className="btn outline-btn" target="_blank">
                      Booking now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p className="mb-2">
                If you need custom services or Need more?
                <a href="#/" className="color-secondary">
                  {" "}
                  Contact us{" "}
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
